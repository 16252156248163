<template>
  <div class="topbox">
    <van-tabs v-model="active" scrollspy sticky>
      <van-tab title="分析结果">
        <div class="container">
          <div class="title">分析结果</div>
          <div class="box">
            <div class="title1"><span class="dot"></span>匹配度雷达</div>
            <div
              id="main"
              :options="options"
              style="width: 100%; height: 5rem"
            ></div>
          </div>

          <div class="box" v-if="pos_tags && pos_tags.length > 0">
            <div class="title1"><span class="dot"></span>适配岗位推荐度</div>
            <div class="score" v-for="(item, index) in pos_tags" :key="index">
              <div>{{ item.tag_name }}</div>
              <van-rate
                readonly
                allow-half
                v-model="item.tag_weight"
                :size="23"
                color="#ffd21e"
                :count="9"
              />
            </div>
          </div>
          <div class="box" v-if="pos_types && pos_types.length > 0">
            <div class="title1"><span class="dot"></span>职位类型符合度</div>
            <div class="score" v-for="(item, index) in pos_types" :key="index">
              <div>{{ item.tag_name }}</div>
              <van-rate
                readonly
                allow-half
                v-model="item.tag_weight"
                :size="23"
                color="#ffd21e"
                :count="9"
              />
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="技能相关">
        <div class="container">
          <div class="title">技能相关</div>
          <div class="box">
            <div class="title1">
              <span class="dot"></span>技能标签
              <span class="txtx">文字越大 能力越强</span>
            </div>
            <div
              id="cloudid"
              :style="'position: relative;height:265px;width:' + cloudX + 'px'"
            ></div>
            <!-- <div id="cloudid" class="relative flex_grow_1" :style="{height: cloudidHeight,width: cloudidWidth}"
                style="overflow: hidden;line-height: normal">

            </div> -->
            <!-- <div style="text-align:center;margin:0.4rem 0;">
              <img style="width:100%;" src="../../assets/1660448889553.jpg" alt="">
            </div> -->
          </div>
          <div class="box" v-if="pos_tags && pos_tags.length > 0">
            <div class="title1"><span class="dot"></span>重点技能分析</div>
            <div
              class="score"
              v-for="(item, index) in skills_tags"
              :key="index"
            >
              <div>{{ item.tag_name }}</div>
              <van-rate
                readonly
                allow-half
                v-model="item.tag_weight"
                :size="23"
                color="#ffd21e"
                :count="9"
              />
            </div>
          </div>
          <div class="box" v-if="detail.cont_job_skill != ''">
            <div class="title1"><span class="dot"></span>个人技能</div>
            <div class="">
              {{ detail.cont_job_skill }}
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="个人属性">
        <div class="container">
          <div class="title">个人属性</div>
          <div class="box">
            <div class="title1"><span class="dot"></span>个人信息</div>
            <div class="txt3">{{ detail.name }}</div>
            <div class="detail_list1">
              <div class="detail_list1Item">
                性别：
                <span v-if="detail.gender == 1">男</span>
                <span v-else-if="detail.gender == 2">女</span>
              </div>
              <div class="detail_list1Item">
                年龄：<span>{{ detail.age }}</span>
              </div>
              <div class="detail_list1Item">
                身高：<span>
                  {{
                    Number(detail.height) > 0 ? detail.height + "cm" : "保密"
                  }}</span
                >
              </div>
              <div class="detail_list1Item">
                体重：<span>
                  {{
                    Number(detail.weight) > 0 ? detail.weight + "kg" : "保密"
                  }}</span
                >
              </div>
              <div class="detail_list1Item">
                当前所在城市：<span>{{ detail.current_location }}</span>
              </div>
              <div class="detail_list1Item">
                当前职称：<span>{{ detail.current_job_title }}</span>
              </div>
              <div class="detail_list1Item">
                当前薪水上限(月)：<span>{{
                  Number(detail.current_salary_max) > 0
                    ? detail.current_salary_max
                    : "保密"
                }}</span>
              </div>
              <div class="detail_list1Item">
                当前薪水下限(月)：<span>{{
                  Number(detail.current_salary_min) > 0
                    ? detail.current_salary_min
                    : "保密"
                }}</span>
              </div>
              <div class="detail_list1Item">
                期望薪水上限(月)：<span>{{
                  Number(detail.expected_salary_max) > 0
                    ? detail.expected_salary_max
                    : "保密"
                }}</span>
              </div>
              <div class="detail_list1Item">
                期望薪水上限(月)：<span>{{
                  Number(detail.expected_salary_min) > 0
                    ? detail.expected_salary_min
                    : "保密"
                }}</span>
              </div>
            </div>
            <div style="padding: 0.25rem 0">
              <div class="detail_list1Item">
                预估薪资：<span>{{
                  detail.estimated_salary
                    ? detail.estimated_salary + "元/月"
                    : "保密"
                }}</span>
              </div>
              <div class="detail_list1Item" style="width: 100%">
                注：结合行业平均薪资，由机器学习模型测算得出，仅供参考
              </div>
            </div>
          </div>
          <div class="box">
            <div class="detail_list1Item">
              期望薪资：<span>{{
                detail.expect_salary ? detail.expect_salary : "0"
              }}</span>
            </div>
            <div class="detail_list1Item" style="width: 100%">
              注：根据简历实际填写值 (0表示无填写)
            </div>
          </div>
          <div class="box">
            <div class="detail_list1Item">
              当前从事行业：<span>{{
                detail.current_industry ? detail.current_industry : "暂无"
              }}</span>
            </div>
            <div class="detail_list1Item" style="width: 100%">
              当前所在公司：<span> {{ detail.current_company }}</span>
            </div>
          </div>
          <div class="box">
            <div class="title1"><span class="dot"></span>专业技能</div>
            <div class="">
              {{ detail.skills ? detail.skills : "暂无数据" }}
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="求职意愿">
        <div class="container">
          <div class="title">求职意愿</div>
          <div class="box" style="border-bottom: none">
            <div class="title1"><span class="dot"></span>期望工作</div>
            <div class="detail_list1Item" style="margin: 0.1rem 0">
              期望月薪 (上限)：<span>{{ detail.expected_salary_max }}</span>
            </div>
            <div class="detail_list1Item" style="margin: 0.1rem 0">
              期望月薪 (下限)：<span>{{ detail.expected_salary_min }}</span>
            </div>
            <div class="detail_list1Item" style="margin: 0.1rem 0">
              期望工作地点：<span>{{ detail.expected_location }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="教育相关">
        <div class="container">
          <div class="title">教育相关</div>
          <div class="box" style="border-bottom: none">
            <div class="title1"><span class="dot"></span>教育经历</div>
            <div v-if="educational_experience.length > 0">
              <div v-for="(item, index) in educational_experience" :key="index">
                <div class="detail_list1Item txt4">
                  教育经历开始时间：<span>{{
                    item.education_experience_start_time
                  }}</span>
                </div>
                <div class="detail_list1Item txt4">
                  教育经历结束时间：<span>{{
                    item.education_experience_end_time
                  }}</span>
                </div>
                <div class="detail_list1Item txt4">
                  毕业院校：<span>{{ item.institution_name }}</span>
                </div>
                <div class="detail_list1Item txt4">
                  所学专业：<span>{{ item.education_field }}</span>
                </div>
                <div class="detail_list1Item txt4">
                  毕业院校排名：<span>{{ item.institution_ranking }}</span>
                </div>
                <div class="detail_list1Item txt4">
                  学历：<span>{{
                    getEducation_level(item.education_level)
                  }}</span>
                </div>
              </div>
            </div>
            <div
              style="text-align: center; margin-top: 1rem; font-size: 0.25rem"
              v-else
            >
              <img
                style="width: 2.5rem; margin-top: 2rem"
                src="../../assets/no_msg.4a8aaa9.png"
                alt=""
              />
              <div style="margin-top: 0.1rem; color: #606266">
                --- 暂无数据 ---
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="项目能力">
        <div class="container">
          <div class="title">项目能力</div>
          <div v-if="projectList && projectList.length > 0">
            <div
              class="box"
              style="border-bottom: none"
              v-for="(item, index) in projectList"
              :key="index"
            >
              <div class="title1"><span class="dot"></span>项目经历</div>
              <div
                class="detail_list1Item txt4"
                v-if="item.project_experience_start_time"
              >
                项目名称：<span>{{ item.project_name }}</span>
              </div>
              <div
                class="detail_list1Item txt4"
                v-if="item.project_experience_start_time"
              >
                项目开始时间：<span>{{
                  item.project_experience_start_time
                }}</span>
              </div>
              <div
                class="detail_list1Item txt4"
                v-if="item.project_experience_end_time"
              >
                项目结束时间：<span>{{
                  item.project_experience_end_time
                }}</span>
              </div>
              <div
                class="detail_list1Item txt4"
                v-if="item.project_description"
              >
                项目内容：
                <div class="">{{ item.project_description }}</div>
              </div>
              <!-- <div  class="detail_list1Item txt4">项目职责： 
                <div class="txt2">负责模块的开发计划、测开发需求、测试报告,执行需求所要达到的目的,开发过程中做开发测试,使用工具定位问题,记录并跟踪项目问题。
                </div> 
              </div> -->
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 1rem; font-size: 0.25rem"
            v-else
          >
            <img
              style="width: 2.5rem; margin-top: 2rem"
              src="../../assets/no_msg.4a8aaa9.png"
              alt=""
            />
            <div style="margin-top: 0.1rem; color: #606266">
              --- 暂无数据 ---
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="就职历史">
        <div class="container">
          <div class="title">就职历史</div>
          <div v-if="work_resume && work_resume.length > 0">
            <div
              class="box"
              style="border-bottom: none"
              v-for="(item, index) in work_resume"
              :key="index"
            >
              <div class="title1"><span class="dot"></span>工作经历</div>
              <div class="detail_list1Item txt4">
                公司名称：<span>{{ item.company_name }}</span>
              </div>
              <div class="detail_list1Item txt4">
                参加工作时间：<span>{{ item.work_experience_start_time }}</span>
              </div>
              <div class="detail_list1Item txt4">
                工作职能：<span>{{
                  item.job_function ? item.job_function : "暂无"
                }}</span>
              </div>
              <div class="detail_list1Item txt4">
                当前职位：<span>{{ item.job_title }}</span>
              </div>
              <div class="detail_list1Item txt4">
                工作描述：
                <div class="">
                  {{ item.job_description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import * as echarts from "echarts";
import jsCloud from "../../../util/jsCloud";
export default {
  data() {
    return {
      type: 1,
      value: 2,
      active: 0,
      detail: {},
      industries: [],
      pos_types: [],
      pos_tags: [],
      skills_tags: [],
      pos_tagsCount: 0,
      cloudidHeight: "10rem",
      cloudidWidth: "100%",
      educational_experience: [],
      work_resume: [],
      projectList: [],
      options: {},
      cloudX: "360",
      // parseInt((window.innerWidth / 100) * 75),
    };
  },
  mounted() {
    this.getDetail();
    console.log(parseInt((window.innerWidth / 100) * 75));
  },
  methods: {
    change(type) {
      this.type = type;
    },
    // 所学专业
    getEducation_level(type = 0) {
      let str;
      switch (Number(type)) {
        case 0:
          str = "高中以下";
          break;
        case 1:
          str = "高中";
          break;
        case 2:
          str = "大专";
          break;
        case 3:
          str = "本科";
          break;
        case 4:
          str = "硕士";
          break;
        case 5:
          str = "博士";
          break;
        case 6:
          str = "EMBA";
          break;
        case 7:
          str = "MBA";
          break;
      }
      return str;
    },
    getDetail() {
      this.$http
        .post("/firm/v1/record/analysisReport", {
          reqType: "record",
          base_id: this.$route.query.id,
        })
        .then((res) => {
          this.detail = JSON.parse(res.data).data;

          let arr = JSON.parse(JSON.parse(res.data).data.assessment);
          this.industries = arr.industries;
          this.pos_types = arr.pos_types;
          this.pos_tags = arr.pos_tags;
          this.skills_tags = arr.skills_tags;
          if (this.pos_tags.length > 0) {
            let num = 0;
            this.pos_tags.forEach((item) => {
              num += Number(item.tag_weight.toFixed(2));
            });
            this.pos_tagsCount = Number(num.toFixed(2));
          }
          if (this.detail.education_experience) {
            this.educational_experience = JSON.parse(
              this.detail.education_experience
            );
          }
          if (this.detail.work_experience) {
            this.work_experience = JSON.parse(this.detail.work_experience);
          }
          if (this.detail.project_experience) {
            this.projectList = JSON.parse(this.detail.project_experience);
          }
          if (this.detail.work_experience) {
            this.work_resume = JSON.parse(this.detail.work_experience);
          }
          console.log(this.work_experience);
          setTimeout(() => {
            this.initEacharts();
            this.jsCloudMeth();
          }, 1000);
        });
    },
    // // 初始化智能标签
    // initTags() {
    //   if (!this.skills_tags) {
    //     return false
    //   }

    //   let word_array = [];
    //   this.skills_tags.forEach((item, index) => {
    //     let obj1 = {
    //       text: item.tag_name,
    //       weight: item.tag_weight
    //     };
    //     word_array.push(obj1);
    //   });
    //   console.log(word_array.length)
    //   if (word_array.length < 11) {
    //       this.cloudidHeight = '21.4rem';
    //       this.cloudidWidth = '53.5rem';
    //     } else if (word_array.length < 21) {
    //       this.cloudidHeight = '23.5rem';
    //       this.cloudidWidth = '53.5rem';
    //     } else if (word_array.length < 31) {
    //       this.cloudidHeight = '25.7rem';
    //       this.cloudidWidth = '53.5rem';
    //     } else if (word_array.length < 41) {
    //       this.cloudidHeight = '27.1rem';
    //       this.cloudidWidth = '53.5rem';
    //     } else if (word_array.length < 51) {
    //       this.cloudidHeight = '28.5rem';
    //       this.cloudidWidth = '53.5rem';
    //     }
    //   jsCloud(document.getElementById("cloudid"), word_array);
    // },
    jsCloudMeth() {
      let arr = [];
      this.skills_tags.forEach((e) => {
        arr.push({
          text: e.tag_name,
          weight: e.tag_weight,
        });
      });
      var word_array = arr;
      jsCloud(document.getElementById("cloudid"), word_array);
    },
    // 雷达图
    initEacharts() {
      var myChart = echarts.init(document.getElementById("main"));
      let obj1, obj2;
      obj1 = JSON.parse(this.detail.capacity_sim_obj);
      obj2 = JSON.parse(this.detail.expect_sim_obj);

      let arr = [];
      let arr1 = [];
      arr[0] = Number(obj1.degree_sim).toFixed(2);
      arr[1] = Number(obj1.work_year_sim).toFixed(2);
      arr[2] = Number(this.detail.capacity_sim).toFixed(2);
      arr[3] = Number(this.detail.job_exp_sim).toFixed(2);
      arr[4] = Number(obj2.title_sim).toFixed(2);
      arr[5] = Number(obj1.salary_sim).toFixed(2);

      // 教育学历 1 obj1.degree_sim
      // 工作年限 1 obj1.work_year_sim
      // 个人能力 1 this.dataLIst.capacity_sim
      // 工作经历 1 this.dataLIst.job_exp_sim
      // 期望工作 1 obj2.title_sim
      // 薪资水平 1 obj2.salary_sim

      arr.forEach((item, index) => {
        arr1.push(this.randomNum(7, 9));
      });
      console.log(arr, arr1);

      var option;
      option = {
        radar: {
          indicator: [
            {
              name: "教育学历匹配度",
              max: 10,
            },
            {
              name: "工作年限匹配度",
              max: 10,
            },
            {
              name: "个人能力匹配度",
              max: 10,
            },
            {
              name: "工作经历匹配度",
              max: 10,
            },
            {
              name: "期望工作匹配度",
              max: 10,
            },
            {
              name: "薪资水平匹配度",
              max: 10,
            },
          ],
        },
        color: ["#0171C2", "#ff8738"],
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: arr1,
                name: "岗位平均标准",
              },
              {
                value: arr,
                name: "当前候选人",
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    // 取随机阈值
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10);
          break;
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          break;
        default:
          return 0;
          break;
      }
    },
  },
  // components:{
  //   eacharts
  // }
};
</script>
<style scoped>
.tabList {
  display: flex;
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  background-color: #fff;
}
.tabItem {
  width: 25%;
  text-align: center;
  font-size: 0.27rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #949cb7;
}
.active {
  font-size: 0.27rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #242f57;
  border-bottom: 0.05rem solid #ff4822;
}
.container {
  background: #fff;
  padding: 0.25rem 0;
  margin-top: 0.25rem;
}
.title {
  width: 2rem;
  padding-left: 0.25rem;
  box-sizing: border-box;
  color: #fff;
  height: 0.65rem;
  line-height: 0.65rem;
  background: linear-gradient(90deg, #ff8e30 0%, #ff541e 100%);
  border-radius: 0 0.5rem 0.5rem 0px;
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.title1 {
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #242f57;
  display: flex;
  align-items: center;
  /* margin-left:0.3rem;
  margin-top:0.2rem; */
}
.dot {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background: #ff4822;
  margin-right: 0.2rem;
}
.box {
  margin: 0.25rem;
  border-bottom: 0.01rem solid #eee;
  padding: 0.2rem 0;
}
.score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
  font-size: 0.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242f57;
}
.txtx {
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #696b6f;
  margin-left: 0.25rem;
}
.txt2 {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242f57;
  margin: 0.1rem 0;
}
.txt3 {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 0.3rem 0;
}
.txt4 {
  margin: 0.1rem 0;
  width: 100%;
}
.topbox >>> .van-tabs__line {
  background-color: #ff4822 !important;
}

.jqcloud >>> .w10 {
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4ac9f0 !important;
}

.jqcloud >>> .w9 {
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bbe2f5;
  display: none;
}

.jqcloud >>> .w8 {
  font-size: 0.65rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90a0dd;
}

.jqcloud >>> .w7 {
  font-size: 0.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bbe2f5;
}

.jqcloud >>> .w6 {
  font-size: 0.55rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90c5f0;
}

.jqcloud >>> .w5 {
  font-size: 0.45rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90c5f0;
}

.jqcloud >>> .w4 {
  font-size: 0.35rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90c5f0;
}

.jqcloud >>> .w3 {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a0ddff;
}

.jqcloud >>> .w2 {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9ce;
}

.jqcloud >>> .w1 {
  font-size: 0.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aab5f0;
}
</style>